export default defineNuxtRouteMiddleware(async (to, from) => {
  // Variable for breadcrumb params
  let breadcrumb = {}
  // split to check if it is coming with categories or author
  let split = from.matched[0].path.split('/')
  const { getCategoryBySlug, getAuthorBySlug, getPostBySlug } = useBlogData()
  
  if (split[2] != '' && split[2] != ':slug()') {    

    let label = ''
    if (split[2] == "categories") {
      const { data: category, error: catError } = await getCategoryBySlug(from.params.slug);
      label = category.value.category
    } else if (split[2] == 'authors') {
      const { data: author, error: authorError } = await getAuthorBySlug(from.params.slug);
      label = author.value.name
    }

    // Assign object values
    breadcrumb.type = split[2]
    breadcrumb.slug = from.params.slug
    breadcrumb.label = label
    // Assign to next page params
    to.params.breadcrumb = breadcrumb
  }
  else if (from.params.slug != undefined) {
    const { data: post, error: postError } = await getPostBySlug(from.params.slug);
    // Assign object values
    if (postError.value) {
      throw createError({ statusCode: 404, statusMessage: 'Page not found' });
    }    
    to.params.post = post
  }
  
})